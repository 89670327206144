/* eslint-disable no-async-promise-executor */
import Axios from "axios";
import constant from "../constant";
import api from "../api";

// eslint-disable-next-line import/no-anonymous-default-export
export default {

  SEARCH_MASTER_CAR: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.SEARCH_MASTER_CAR(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  REQUEST_BOOKING: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.REQUEST_BOOKING(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  REQUEST_VENDOR_BOOKING: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.REQUEST_VENDOR_BOOKING(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  CANCEL_BOOKING: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.CANCEL_BOOKING(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_BOOKING: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.GET_BOOKING()
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  BOOKING_DETAIL: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.order.BOOKING_DETAIL(id)
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  REQUEST_PAYMENT: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.REQUEST_PAYMENT(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  COMPLETE_PAYMENT: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.COMPLETE_PAYMENT(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  ADD_REVIEW: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.ADD_REVIEW(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  ENABLE_SHARING: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.ENABLE_SHARING(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  CREATE_SHARING_BOOKING: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.CREATE_SHARING_BOOKING(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  REQUEST_OPEN_PAYMENT: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.REQUEST_OPEN_PAYMENT(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  SEARCH_SHARING: (data) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.post(
          constant.BASE_URL + api.order.SEARCH_SHARING(),data
        );
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
};

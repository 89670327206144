import { Link } from "react-router-dom"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"

const Services = () => {
    return (<>
    <Header/>
    <SideBar/>
        <main class="main">

            <div class="site-breadcrumb" style={{background: 'url(assets/img/breadcrumb/01.jpg)'}}>
                <div class="container">
                    <h2 class="breadcrumb-title">Services</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Services</li>
                    </ul>
                </div>
            </div>



            <div className="service-area bg py-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline">Services</span>
                                <h2 className="site-title">Our Best Services For You</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="service-img">
                                    <img src="/assets/img/service/01.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/taxi-booking-1.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Online Booking</a>
                                    </h3>
                                    <p className="service-text">
                                        Effortlessly hail rides anytime, anywhere with our streamlined online cab booking service. Enjoy convenience, and peace of mind as you travel to your destination with just a few clicks.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".50s">
                                <div className="service-img">
                                    <img src="/assets/img/service/02.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/city-taxi.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">One way Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Experience the ease of hassle-free travel with our convenient one-way transport service. Say goodbye to worries and hello to a smooth journey, every time.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".75s">
                                <div className="service-img">
                                    <img src="/assets/img/service/03.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/airport.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Airport Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Experience stress-free travel with our reliable airport transport service. From touchdown to takeoff, we've got your journey covered with punctuality, comfort, and convenience.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="service-img">
                                    <img src="/assets/img/service/04.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/business.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Roundtrip Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Experience the ultimate in convenience with our roundtrip transport service. Enjoy seamless travel to your destination and back, with reliability and comfort guaranteed every step of the way.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".50s">
                                <div className="service-img">
                                    <img src="/assets/img/service/05.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/taxi-2.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Sharing Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Easily share rides and split costs with our innovative taxi pooling service. Reduce your carbon footprint while enjoying a cost-effective and efficient way to travel, all with the convenience of our user-friendly platform.
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-4">
                            <div className="service-item wow fadeInUp" data-wow-delay=".75s">
                                <div className="service-img">
                                    <img src="/assets/img/service/06.jpg" />
                                </div>
                                <div className="service-icon">
                                    <img src="/assets/img/icon/taxi.svg" />
                                </div>
                                <div className="service-content">
                                    <h3 className="service-title">
                                        <a href="#">Tour Transport</a>
                                    </h3>
                                    <p className="service-text">
                                        Embark on unforgettable journeys with our tour wandering transport service. Explore new destinations with comfort, as our expert drivers guide you through breathtaking sights and creating memories that last a lifetime
                                    </p>
                                    <div className="service-arrow">
                                        <Link to="/taxi" className="theme-btn">Book Now<i className="fas fa-arrow-right"></i></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className="counter-area">
                <div className="container">
                    <div className="counter-wrapper">
                        <div className="row">
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/taxi-1.svg" />
                                    </div>
                                    <div>
                                        <span className="counter" data-count="+" data-to="500" data-speed="3000">500</span>
                                        <h6 className="title">+ Available Taxi </h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/happy.svg" />
                                    </div>
                                    <div>
                                        <span className="counter" data-count="+" data-to="900" data-speed="3000">900</span>
                                        <h6 className="title">+ Happy Clients</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/driver.svg" />
                                    </div>
                                    <div>
                                        <span className="counter" data-count="+" data-to="700" data-speed="3000">700</span>
                                        <h6 className="title">+ Our Drivers</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="counter-box">
                                    <div className="icon">
                                        <img src="/assets/img/icon/trip.svg" />
                                    </div>
                                    <div>
                                        <span className="counter" data-count="+" data-to="1800" data-speed="3000">1800</span>
                                        <h6 className="title">+ Road Trip Done</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="feature-area feature-bg py-120">
                <div className="container mt-150">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <div className="site-heading text-center">
                                <span className="site-title-tagline">Feature</span>
                                <h2 className="site-title text-white">Our Awesome Feature</h2>
                                <div className="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/taxi-safety.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>Safety Guarantee</h4>
                                    <p>Your safety is priority. With stringent driver vetting, real-time tracking, and 24/7 support and worry-free ride every time you book through our taxi app</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/pickup.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>Fast Pickup</h4>
                                    <p>Get where you need to be in no time with our quick booking app. Say goodbye to waiting and hello to seamless travel at your fingertips.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item wow fadeInUp" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/money.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>Affordable Rate</h4>
                                    <p> Get to your destination conveniently and economically with our affordable rates, ensuring a smooth ride every time.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                            <div className="feature-item mt-lg-5 wow fadeInDown" data-wow-delay=".25s">
                                <div className="feature-icon">
                                    <img src="/assets/img/icon/support.svg" />
                                </div>
                                <div className="feature-content">
                                    <h4>24/7 Support</h4>
                                    <p>Enjoy peace of mind with our 24/7 support for your taxi bookings. Ensuring a seamless experience from start to finish.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


      


            <div class="testimonial-area py-120 mt-50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 mx-auto">
                            <div class="site-heading text-center">
                                <span class="site-title-tagline"><i class="flaticon-drive"></i> Testimonials</span>
                                <h2 class="site-title text-white">What Our Client <span>Say's</span></h2>
                                <div class="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-slider owl-carousel owl-theme">
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/01.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Sylvia Green</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/02.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Gordo Novak</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/03.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Reid Butt</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/04.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Parker Jime</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/05.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Heruli Nez</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="download-area pt-120">
                <div class="container">
                    <div class="download-wrapper">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="download-content">
                                    <div class="site-heading mb-4">
                                        <span class="site-title-tagline justify-content-start">
                                            <i class="flaticon-drive"></i> Get Our App
                                        </span>
                                        <h2 class="site-title mb-10">Download <span>Our cars53</span> App For Free</h2>
                                        <p>
                                        Unlock seamless transportation at your fingertips - download our Cars53 app for free today! Enjoy easy booking, real-time tracking, and exclusive deals, all in one user-friendly platform.
                                        </p>
                                    </div>
                                    <div class="download-btn">
                                        <a href="https://play.google.com/store/apps/details?id=com.cars53.user">
                                            <i class="fab fa-google-play"></i>
                                            <div class="download-btn-content">
                                                <span>Get It On</span>
                                                <strong>Google Play</strong>
                                            </div>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-app-store"></i>
                                            <div class="download-btn-content">
                                                <span>Get It On</span>
                                                <strong>App Store</strong>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="download-img">
                            <img src="/assets/img/download/01.png" alt />
                        </div>
                    </div>
                </div>
            </div>


            <div class="partner pt-80 pb-80">
                <div class="container">
                    <div class="partner-slider owl-carousel owl-theme">
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/01.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/02.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/03.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/01.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/02.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/03.png" alt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer/>
        </> )
}
export default Services
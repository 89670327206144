/* eslint-disable import/no-anonymous-default-export */
export default {
  SEARCH_MASTER_CAR:()=>`/customer/bookings/searchTaxi`,
  REQUEST_BOOKING:()=>`/customer/bookings/requestBooking`,
  REQUEST_VENDOR_BOOKING:()=>`/customer/bookings/requestVendorBooking`,
  GET_BOOKING:()=>`/customer/bookings/getMyBookings`,
  BOOKING_DETAIL:(id)=>`/customer/bookings/getBookingDetail/${id}`,
  REQUEST_PAYMENT:()=>`/customer/payments/requestPayment`,
  COMPLETE_PAYMENT:()=>`/customer/payments/completePayment`,
  ADD_REVIEW:()=>`/customer/bookings/createReview`,
  ENABLE_SHARING:()=>`/customer/bookings/enableSharing`,
  SEARCH_SHARING:()=>`/customer/bookings/searchSharedTaxi`,
  CREATE_SHARING_BOOKING:()=>`/customer/bookings/createSharedBooking`,
  REQUEST_OPEN_PAYMENT:()=>`/customer/payments/requestOpenPayment`,
  CANCEL_BOOKING:(id)=>`/customer/bookings/cancelBooking/${id}`,

  
};
  
import { Link } from "react-router-dom"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"

const AboutUs = () => {
    return (<>
    <Header/>
    <SideBar/>
        <main class="main">

            <div class="site-breadcrumb" style={{background: 'url(assets/img/breadcrumb/01.jpg)'}}>
                <div class="container">
                    <h2 class="breadcrumb-title">About Us</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">About Us</li>
                    </ul>
                </div>
            </div>


            <div class="about-area py-120">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="about-left wow fadeInLeft" data-wow-delay=".25s">
                                <div class="about-img">
                                    <img src="/assets/img/about/01.png" alt />
                                </div>
                                <div class="about-experience">
                                    <div class="about-experience-icon">
                                        <img src="/assets/img/icon/taxi-booking.svg" alt />
                                    </div>
                                    <b>30 Years Of <br /> Quality Service</b>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="about-right wow fadeInRight" data-wow-delay=".25s">
                                <div class="site-heading mb-3">
                                    <span class="site-title-tagline justify-content-start">
                                        <i class="flaticon-drive"></i> About Us
                                    </span>
                                    <h2 class="site-title">
                                        We Provide Trusted <span>Cab Service</span> In The India
                                    </h2>
                                </div>
                                <p class="about-text">
                                At Cars53, we believe that traveling should be a pleasure, not a chore. That's why we've made it
our mission to provide commuters in Madhya Pradesh and Rajasthan with affordable and
convenient travel solutions.
                                </p>
                                <div class="about-list-wrapper">
                                    <ul class="about-list list-unstyled">
                                    <li>
                                            Quick Availability
                                        </li>
                                        <li>
                                            Affordable Pricing
                                        </li>
                                        <li>
                                            Top-Notch Service
                                        </li>
                                    </ul>
                                </div>
                                <Link to="/taxi" class="theme-btn mt-4">Book Now<i
                                    class="fas fa-arrow-right"></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="counter-area">
                <div class="container">
                    <div class="counter-wrapper mb-0">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-box">
                                    <div class="icon">
                                        <img src="/assets/img/icon/taxi-1.svg" alt />
                                    </div>
                                    <div>
                                        <span class="counter" data-count="+" data-to="500" data-speed="3000">500</span>
                                        <h6 class="title">+ Available Taxi </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-box">
                                    <div class="icon">
                                        <img src="/assets/img/icon/happy.svg" alt />
                                    </div>
                                    <div>
                                        <span class="counter" data-count="+" data-to="900" data-speed="3000">900</span>
                                        <h6 class="title">+ Happy Clients</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-box">
                                    <div class="icon">
                                        <img src="/assets/img/icon/driver.svg" alt />
                                    </div>
                                    <div>
                                        <span class="counter" data-count="+" data-to="700" data-speed="3000">700</span>
                                        <h6 class="title">+ Our Drivers</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-sm-6">
                                <div class="counter-box">
                                    <div class="icon">
                                        <img src="/assets/img/icon/trip.svg" alt />
                                    </div>
                                    <div>
                                        <span class="counter" data-count="+" data-to="1800" data-speed="3000">1800</span>
                                        <h6 class="title">+ Road Trip Done</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


      


            <div class="testimonial-area py-120 mt-50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 mx-auto">
                            <div class="site-heading text-center">
                                <span class="site-title-tagline"><i class="flaticon-drive"></i> Testimonials</span>
                                <h2 class="site-title text-white">What Our Client <span>Say's</span></h2>
                                <div class="heading-divider"></div>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-slider owl-carousel owl-theme">
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/01.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Sylvia Green</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/02.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Gordo Novak</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/03.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Reid Butt</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/04.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Parker Jime</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                        <div class="testimonial-single">
                            <div class="testimonial-content">
                                <div class="testimonial-author-img">
                                    <img src="/assets/img/testimonial/05.jpg" alt />
                                </div>
                                <div class="testimonial-author-info">
                                    <h4>Heruli Nez</h4>
                                    <p>Customer</p>
                                </div>
                            </div>
                            <div class="testimonial-quote">
                                <span class="testimonial-quote-icon"><i class="far fa-quote-right"></i></span>
                                <p>
                                    There are many variations of words suffered available to the have majority but the
                                    majority
                                    suffer to alteration injected hidden the middle text.
                                </p>
                            </div>
                            <div class="testimonial-rate">
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                                <i class="fas fa-star"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="download-area pt-120">
                <div class="container">
                    <div class="download-wrapper">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="download-content">
                                    <div class="site-heading mb-4">
                                        <span class="site-title-tagline justify-content-start">
                                            <i class="flaticon-drive"></i> Get Our App
                                        </span>
                                        <h2 class="site-title mb-10">Download <span>Our cars53</span> App For Free</h2>
                                        <p>
                                        Unlock seamless transportation at your fingertips - download our Cars53 app for free today! Enjoy easy booking, real-time tracking, and exclusive deals, all in one user-friendly platform.
                                        </p>
                                    </div>
                                    <div class="download-btn">
                                        <a href="https://play.google.com/store/apps/details?id=com.cars53.user">
                                            <i class="fab fa-google-play"></i>
                                            <div class="download-btn-content">
                                                <span>Get It On</span>
                                                <strong>Google Play</strong>
                                            </div>
                                        </a>
                                        <a href="#">
                                            <i class="fab fa-app-store"></i>
                                            <div class="download-btn-content">
                                                <span>Get It On</span>
                                                <strong>App Store</strong>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="download-img">
                            <img src="/assets/img/download/01.png" alt />
                        </div>
                    </div>
                </div>
            </div>


            <div class="partner pt-80 pb-80">
                <div class="container">
                    <div class="partner-slider owl-carousel owl-theme">
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/01.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/02.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/03.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/01.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/02.png" alt />
                            </div>
                        </div>
                        <div class="partner-item">
                            <div class="partner-img">
                                <img src="/assets/img/partner/03.png" alt />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer/>
        </> )
}
export default AboutUs
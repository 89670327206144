import { Link } from "react-router-dom"
import DashboardSideBar from "../../components/dashboardSidebar"
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"
import { useEffect, useState } from "react"
import services from '../../services'
import Toaster from "../../components/toaster";

const MyProfile = () => {
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [phoneNumber, setPhoneNumber] = useState()
    const [email, setEmail] = useState()
    const [gender, setGender] = useState()
    const [toaster, setToaster] = useState({ visiblity: "hide" });

    const updateProfileHandler = async() =>{
        try {
            let payload = {
                firstName : firstName,
                lastName :lastName,
                email : email,
                phoneNumber : phoneNumber,
                gender: gender
            }
            console.log(payload)
           const response = await services.auth.UPDATE_PROFILE(payload) 
           console.log(response)
           let data = JSON.parse(localStorage.getItem('userDetails'));
           data.firstName = firstName;
           data.lastName = lastName;
           data.email = email;
           data.phoneNumber = phoneNumber;
           data.gender = gender;
           localStorage.setItem('userDetails', JSON.stringify(data))

           setToaster({
            type: "success",
            title: "Profile update",
            text: 'Profile updated successfully',
            visiblity: "show",
        });
        setTimeout(()=>{
            setToaster({
                type: "danger",
                title: "Error",
                text: 'Error',
                visiblity: "hide",
            });
        },1500)
        } catch (error) {
            console.log(error)
            setToaster({
                type: "danger",
                title: "Error",
                text: 'Error',
                visiblity: "show",
            });
            setTimeout(()=>{
                setToaster({
                    type: "danger",
                    title: "Error",
                    text: 'Error',
                    visiblity: "hide",
                });
            },1500)
        }
    }
    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('userDetails'));
        if (data) {
            setFirstName(data.firstName)
            setLastName(data.lastName)
            setPhoneNumber(data.phoneNumber)
            setEmail(data.email)
            setGender(data.gender)
        }
    },[])
    return (<>
        <Header />
        <SideBar />
        <Toaster
            type={toaster.type}
            title={toaster.title}
            text={toaster.text}
            visiblity={toaster.visiblity}
        />
        <main class="main">
            <div class="user-profile py-5">
                <div class="container">
                    <div class="row">
                        <DashboardSideBar />
                        <div class="col-lg-9">
                            <div class="user-profile-wrapper">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="user-profile-card">
                                            <h4 class="user-profile-card-title">Profile Info</h4>
                                            <div class="user-profile-form">
                                             
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>First Name</label>
                                                                <input type="text" class="form-control" onChange={(e) => setFirstName(e.target.value)} value={firstName}
                                                                    placeholder="First Name" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Last Name</label>
                                                                <input type="text" class="form-control" onChange={(e) => setLastName(e.target.value)} value={lastName}
                                                                    placeholder="Last Name" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Email</label>
                                                                <input type="text" class="form-control" onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label>Phone</label>
                                                                <input type="text" class="form-control" value={phoneNumber} disabled={true} placeholder="Phone" />
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label>Address</label>
                                                                <select  onChange={(e)=>setGender(e.target.value)} value={gender} className="select-custom">
                                                                    <option value>Choose Gender</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <button type="button" onClick={()=>{updateProfileHandler()}} class="theme-btn my-3"><span
                                                        class="far fa-user"></span> Save Changes</button>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 d-none">
                                        <div class="user-profile-card">
                                            <h4 class="user-profile-card-title">Change Password</h4>
                                            <div class="col-lg-12">
                                                <div class="user-profile-form">
                                                    <form action="#">
                                                        <div class="form-group">
                                                            <label>Old Password</label>
                                                            <input type="password" class="form-control"
                                                                placeholder="Old Password" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>New Password</label>
                                                            <input type="password" class="form-control"
                                                                placeholder="New Password" />
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Re-Type Password</label>
                                                            <input type="password" class="form-control"
                                                                placeholder="Re-Type Password" />
                                                        </div>
                                                        <button type="button" class="theme-btn my-3"><span
                                                            class="far fa-key"></span> Change Password</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        {/* <Footer /> */}
    </>
    )
}
export default MyProfile
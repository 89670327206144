import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "../src/views/home";
import './App.css'
import Login from "./views/login";
import Register from "./views/register";
import TaxiListing from "./views/taxiListing";
import TaxiDetail from "./views/taxiListing/detail";
import BookNow from "./views/book";
import MyBookings from "./views/mybookings";
import OrderDetails from "./views/mybookings/detail";
import Dashboard from "./views/dashboard";
import MyProfile from "./views/myProfile";
import Payments from "./views/payments";
import Settings from "./views/setting";
import Rewards from "./views/rewards";
import AboutUs from "./views/about";
import ContactUs from "./views/contact";
import Terms from "./views/terms";
import Privacy from "./views/privacy";
import Services from "./views/services";
import AhlanWasahla from "./views/ahlanwasahla";
import { App as CapacitorApp } from '@capacitor/app';

CapacitorApp.addListener('backButton', ({canGoBack}) => {
  if(!canGoBack){
    CapacitorApp.exitApp();
  } else {
    window.history.back();
  }
});
function App() {
  const isLoggedIn = localStorage.getItem('access_token')
  return (
    <BrowserRouter>
      <Routes>
        <Route>
          <Route index element={<Home />} />
          {!isLoggedIn && <>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
          </>}
          <Route path="taxi" element={<TaxiListing />} />
          {isLoggedIn &&<>
          <Route path="booknow" element={<BookNow />} />
          <Route path="mybookings" element={<MyBookings />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="myprofile" element={<MyProfile />} />
          <Route path="payments" element={<Payments />} />
          <Route path="settings" element={<Settings />} />
          <Route path="rewards" element={<Rewards />} />
          <Route path="booking/:id" element={<OrderDetails />} />
          </>}
          <Route path="contact" element={<ContactUs />} />
          <Route path="terms" element={<Terms />} />
          <Route path="privacy" element={<Privacy />} />
          
          <Route path="about" element={<AboutUs />} />
          <Route path="services" element={<Services />} />
          <Route path="taxi/:id" element={<TaxiDetail />} />
          
          <Route path="iftetah" element={<AhlanWasahla />} />
          <Route path="*" element={<Navigate to={'/'}/>} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;

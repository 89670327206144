import { Link } from "react-router-dom"
import { useEffect, useState } from "react";
import services from '../services/index'
const Header = () => {
    const [rewardBalance, setRewardBalance] = useState(0)
    useEffect(() => {
        if(localStorage.getItem('access_token')){
            getMyProfile()
        }
        setTimeout(()=>{
        scriptHandler();

        },500)


    }, [])
    const getMyProfile = async() => {
        try {
            const response = await services.auth.GET_PROFILE();
            console.log(response)
            localStorage.setItem('userDetails', JSON.stringify(response.data))
            setRewardBalance(response.data.rewardBalance)
        } catch (error) {
            console.log(error)
        }
    }
    const scriptHandler = async () => {
        const script = document.getElementById("module-scripts");
        if (!script) {

            const scriptSources = [
                '/assets/js/jquery-3.6.0.min.js',
                '/assets/js/modernizr.min.js',
                '/assets/js/bootstrap.bundle.min.js',
                '/assets/js/imagesloaded.pkgd.min.js',
                '/assets/js/jquery.magnific-popup.min.js',
                '/assets/js/isotope.pkgd.min.js',
                '/assets/js/jquery.appear.min.js',
                '/assets/js/jquery.easing.min.js',
                '/assets/js/owl.carousel.min.js',
                '/assets/js/counter-up.js',
                '/assets/js/jquery-ui.min.js',
                '/assets/js/jquery.timepicker.min.js',
                '/assets/js/jquery.nice-select.min.js',
                '/assets/js/wow.min.js',
            ];
            await Promise.all(scriptSources.map((src) => {
                const script = document.createElement('script');
                script.src = src;
                script.id = 'module-scripts'
                //   new
                // Append script to the body
                document.body.appendChild(script);
            }))
            const script = document.createElement('script');
            script.src = '/assets/js/main.js';
            script.id = 'module-scripts'
            //   new
            // Append script to the body
            document.body.appendChild(script);
        }
    }
    const logoutHandler = () =>{
        localStorage.clear();
        window.location.reload()
    }
    const isLoggedIn = localStorage.getItem('access_token') ? true : false;
    const isMobile = window.innerWidth < 768;
    return (
        <header className="header">

            <div className="header-top">
                <div className="container">
                    <div className="header-top-wrapper">
                        <div className="header-top-left">
                            <div className="header-top-contact">
                                <ul>
                                  
                                    <li><a
                                        href="mailto:car53app@gmail.com"><i
                                            className="far fa-envelopes"></i> &nbsp;
                                        <span>car53app@gmail.com</span></a>
                                    </li>
                                    <li><a href="tel:+919479525453"><i className="far fa-phone-volume"></i> +91 94795 25453</a>
                                    </li>
                                    <li><a href="#"><i className="far fa-alarm-clock"></i> Sun - Fri (08AM - 10PM)</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="header-top-right">
                        {!isMobile && <li><div id="google_translate_element"></div></li>}
                           {!isLoggedIn && <div className="header-top-link">
                                <Link to="/login"><i className="far fa-arrow-right-to-bracket"></i> Login</Link>
                            </div>}
                            
                            <div className="header-top-social">
                                <span>Follow Us: </span>
                                <a href="#"><i className="fab fa-facebook"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                                <a href="#"><i className="fab fa-linkedin"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-navigation">
                <nav className="navbar navbar-expand-lg">
                    <div className="container position-relative">
                        <Link className="navbar-brand" to="/">
                            <img src="/assets/img/logo/logo.png" alt="logo" />
                        </Link>
                        {isLoggedIn ? <div className="mobile-menu-right">
                           
                            <div class="nav-right-account">
                                <div class="dropdown">
                                    <div data-bs-toggle="dropdown" aria-expanded="false">
                                        <img src="/assets/img/account/avatar.webp" />
                                    </div>
                                    <ul class="dropdown-menu dropdown-menu-end">
                                        <li><Link class="dropdown-item" to="/dashboard"><i
                                            class="far fa-gauge-high"></i> Dashboard</Link></li>
                                        <li><Link class="dropdown-item" to="/myprofile"><i class="far fa-user"></i> My
                                            Profile</Link></li>
                                        <li><Link class="dropdown-item" to="/mybookings"><i
                                            class="far fa-layer-group"></i> My Booking</Link></li>
                                        <li><Link class="dropdown-item" to="/payments"><i
                                            class="far fa-credit-card"></i> Payment History</Link></li>
                                        <li><Link class="dropdown-item" to="/settings"><i
                                            class="far fa-cog"></i> Settings</Link></li>
                                        <li onClick={logoutHandler}><Link class="dropdown-item" to="/"><i class="far fa-sign-out"></i> Logout</Link></li>
                                        {isMobile && <li><a className="dropdown-item"><div id="google_translate_element" className="mobile-widget"></div></a></li>}
                                            <li><hr/></li>
                                     
                                        <li><Link class="dropdown-item" to="/"> 
                                    <a href="#" className="theme-btn btn-sm"><span className="fas fa-gift"></span>{rewardBalance}KM Earned</a>
                                </Link></li>
                                    </ul>
                                </div>
                            </div>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-mobile-icon"><i className="far fa-bars"></i></span>
                            </button>
                        </div> : <Link to="/login" className="theme-btn btn-sm d-lg-none"><span className="fas fa-arrow-right-to-bracket"></span>Login</Link>}
                        <div className="collapse navbar-collapse" id="main_nav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className="nav-link active" to="/" data-bs-toggle="dropdown">Home</Link>

                                </li>
                                <li className="nav-item"><Link className="nav-link" to="/about">About</Link></li>
                                <li className="nav-item ">
                                    <Link className="nav-link" to="/nearby">Nearby Taxis</Link>

                                </li>
                                <li className="nav-item ">
                                    <Link className="nav-link" to="/services">Service</Link>

                                </li>

                                {/* <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown">Blog</a>
                            <ul className="dropdown-menu fade-down">
                                <li><a className="dropdown-item" href="blog.html">Blog</a></li>
                                <li><a className="dropdown-item" href="blog-single.html">Blog Single</a></li>
                            </ul>
                        </li> */}

                            </ul>
                            <div className="nav-right">
                                <div class="nav-right-account">
                                    {isLoggedIn && <div class="dropdown">
                                        <div data-bs-toggle="dropdown" aria-expanded="false">
                                            <img src="/assets/img/account/avatar.webp" />
                                        </div>
                                        <ul class="dropdown-menu dropdown-menu-end">
                                            <li><Link class="dropdown-item" to="/dashboard"><i
                                                class="far fa-gauge-high"></i> Dashboard</Link></li>
                                            <li><Link class="dropdown-item" to="/myprofile"><i class="far fa-user"></i> My
                                                Profile</Link></li>
                                            <li><Link class="dropdown-item" to="/mybookings"><i
                                                class="far fa-layer-group"></i> My Booking</Link></li>
                                                <li><Link class="dropdown-item" to="/payments"><i
                                            class="far fa-credit-card"></i> Payment History</Link></li>
                                            <li><Link class="dropdown-item" to="/settings"><i
                                                class="far fa-cog"></i> Settings</Link></li>
                                            <li onClick={logoutHandler}><Link class="dropdown-item" to="/"><i class="far fa-sign-out"></i> Logout</Link></li>
                                        </ul>
                                    </div>}
                                </div>
                               { isLoggedIn && <div className="nav-right-btn mt-2">
                                    <a href="#" className="theme-btn btn-sm"><span className="fas fa-gift"></span>{rewardBalance}KM Earned</a>
                                </div>}
                                <div className="sidebar-btn">
                                    <button type="button" className="nav-right-link"><i className="far fa-bars-filter"></i></button>
                                </div>
                            </div>
                        </div>

                        <div className="search-area">
                            <form action="#">
                                <div className="form-group">
                                    <input type="text" className="form-control" placeholder="Type Keyword..." />
                                    <button type="submit" className="search-icon-btn"><i className="far fa-search"></i></button>
                                </div>
                            </form>
                        </div>

                    </div>
                </nav>
            </div>
        </header>
    )
}
export default Header
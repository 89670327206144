import { Link } from "react-router-dom"

const DashboardSideBar = () => {
    let windowName = window.location.href;
    const logoutHandler = () =>{
        localStorage.clear();
        window.location.reload()
    }
    return (
        <div class="col-lg-3 my-4 my-lg-0">
        <div class="user-profile-sidebar d-none d-lg-block">
            <div class="user-profile-sidebar-top">
                <div class="user-profile-img">
                    <img src="/assets/img/account/avatar.webp" alt/>
                    <button type="button" class="profile-img-btn"><i class="far fa-camera"></i></button>
                    <input type="file" class="profile-img-file"/>
                </div>
                <h5>Antoni Jonson</h5>
                <p><a href="mailto:car53app@gmail.com" class="__cf_email__"
                        >car53app@gmail.com</a>
                </p>
            </div>
            <ul class="user-profile-sidebar-list">
                <li><Link class={windowName.includes('dashboard') && 'active'} to="/dashboard"><i class="far fa-gauge-high"></i> Dashboard</Link></li>
                <li><Link class={windowName.includes('myprofile') && 'active'} to="/myprofile"><i class="far fa-user"></i> My Profile</Link></li>
                <li><Link class={windowName.includes('mybookings') && 'active'} to="/mybookings"><i class="far fa-layer-group"></i> My
                        Booking</Link></li>
               
                <li><Link class={windowName.includes('payments') && 'active'} to="/payments"><i class="far fa-credit-card"></i> Payment
                        History</Link></li>
                        {/* <li><Link class={windowName.includes('rewards') && 'active'} to="/rewards"><i class="far fa-gift"></i> KM Rewards</Link></li> */}
                <li><Link class={windowName.includes('settings') && 'active'} to="/settings"><i class="far fa-gear"></i> Settings</Link></li>
                <li onClick={logoutHandler}><a href="#"><i class="far fa-sign-out"></i> Logout</a></li>
            </ul>
        </div>
    </div>
    )
}
export default DashboardSideBar
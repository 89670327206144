const SideBar = () => {
    return (
        <div className="sidebar-popup">
            <div className="sidebar-wrapper">
                <div className="sidebar-content">
                    <button type="button" className="close-sidebar-popup"><i className="far fa-xmark"></i></button>
                    <div className="sidebar-logo">
                        <img src="/assets/img/logo/logo.png" />
                    </div>
                    <div className="sidebar-about">
                        <h4>About Us</h4>
                        <p>There are many variations of passages available sure there majority have suffered alteration in
                            some form by injected humour or randomised words which don't look even slightly believable.</p>
                    </div>
                    <div className="sidebar-contact">
                        <h4>Contact Info</h4>
                        <ul>
                            <li>
                                <h6>Email</h6>
                                <a
                                    href="mailto:car53app@gmail.com"><i
                                        className="far fa-envelope"></i><span className="__cf_email__"
                                            >car53app@gmail.com</span></a>
                            </li>
                            <li>
                                <h6>Phone</h6>
                                <a href="tel:+919479525453"><i className="far fa-phone"></i>+91 94795 25453</a>
                            </li>
                            <li>
                                <h6>Address</h6>
                                <a href="#"><i className="far fa-location-dot"></i>Ratlam, MP, India</a>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar-social">
                        <h4>Follow Us</h4>
                        <a href="#"><i className="fab fa-facebook"></i></a>
                        <a href="#"><i className="fab fa-twitter"></i></a>
                        <a href="#"><i className="fab fa-instagram"></i></a>
                        <a href="#"><i className="fab fa-linkedin"></i></a>
                    </div>
                </div>
            </div>
        </div>
    )
    }
    export default SideBar
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"

const Privacy = () => {
    return (<>
        <Header />
        <SideBar />
        <main class="main">

            <div class="site-breadcrumb" style={{ background: 'url(assets/img/breadcrumb/01.jpg)' }}>
                <div class="container">
                    <h2 class="breadcrumb-title">Privacy Policy</h2>
                    <ul class="breadcrumb-menu">
                        <li><a href="index.html">Home</a></li>
                        <li class="active">Privacy Policy</li>
                    </ul>
                </div>
            </div>


            <div class="pt-120 pb-120">
                <div class="container">
                    <div class="row">
                        <div class="col">
                            <div class="terms-content">
                                <h3>Privacy Policy</h3>
                                <strong>Acceptance of Terms:</strong>
                                <p>By accessing or using the Cars53 app, users and vendors agree to comply with these terms and conditions.</p>

                                <strong>Service Description:</strong>
                                <p>Cars53 acts as a platform connecting users with transportation vendors. We facilitate bookings and transactions but do not own or operate vehicles.</p>

                                <strong>User Responsibilities:</strong>
                                <p>Users must provide accurate information, follow safety guidelines, and treat vendors respectfully. Misuse of the platform may result in account suspension.</p>

                                <strong>Vendor Responsibilities:</strong>
                                <p>Vendors are responsible for maintaining their vehicles, complying with regulations, and delivering quality service to users. Failure to do so may lead to account suspension.</p>

                                <strong>Payment Terms:</strong>
                                <p>Users agree to pay for rides booked through the app as per displayed pricing. Vendors receive payments for services rendered, minus applicable fees.</p>

                                <strong>Liability Limitations:</strong>
                                <p>Cars53 is not liable for damages, injuries, or disputes arising from user-vendor interactions. Users and vendors use the platform at their own risk.</p>

                                <strong>Governing Law:</strong>
                                <p>These terms are governed by the laws of the jurisdiction where Cars53 operates. Disputes shall be resolved through arbitration or in courts of competent jurisdiction.</p>

                                <strong>Policy Updates:</strong>
                                <p>Cars53 reserves the right to modify these terms at any time. Users and vendors will be notified of changes, and continued use implies acceptance of the revised terms.</p>

                                <strong>Privacy Policy - Consumers:</strong>

                                <strong>Information Collection:</strong>
                                <p>We collect personal details like name, contact information, and payment data to facilitate bookings and transactions. Usage data is also gathered for service improvement.</p>

                                <strong>Data Usage:</strong>
                                <p>Personal information is used for providing services, processing payments, and enhancing user experience. We do not share data with third parties without consent, except as required by law.</p>

                                <strong>Data Security:</strong>
                                <p>We employ industry-standard security measures to protect user data from unauthorized access, alteration, or disclosure. Information is encrypted and stored securely.</p>

                                <strong>Third-Party Services:</strong>
                                <p>The app may contain links to third-party services. We are not responsible for their privacy practices and recommend reviewing their policies before engaging with them.</p>

                                <strong>Policy Updates:</strong>
                                <p>We reserve the right to update our privacy policy periodically. Users will be informed of changes, and continued use of our services implies acceptance of the revised policy.</p>

                                <strong>Privacy Policy - Vendors:</strong>

                                <strong>Vendor Information:</strong>
                                <p>Vendors provide personal and business information for registration. This includes contact details, vehicle information, and payment details.</p>

                                <strong>Data Usage:</strong>
                                <p>Vendor data is utilized for facilitating bookings, processing payments, and communicating with users. Aggregated data may be analyzed for business insights.</p>

                                <strong>Data Security:</strong>
                                <p>We prioritize vendor data security and implement robust measures to prevent unauthorized access or misuse. Access to vendor information is restricted to authorized personnel.</p>

                                <strong>Service Partners:</strong>
                                <p>Vendor information may be shared with service partners like payment processors to facilitate transactions and ensure compliance with regulations.</p>

                                <strong>Policy Updates:</strong>
                                <p>Our privacy policy may be updated periodically to reflect changes in practices or legal requirements. Vendors will be notified, and continued use of the platform constitutes acceptance of the revised policy.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
        <Footer />
    </>
    )
}
export default Privacy
import Footer from "../../components/footer"
import Header from "../../components/header"
import SideBar from "../../components/sidebar"

const Terms = () => {
    return (<>
    <Header/>
    <SideBar/>
        <main class="main">

        <div class="site-breadcrumb" style={{background: 'url(/assets/img/breadcrumb/01.jpg)'}}>
            <div class="container">
                <h2 class="breadcrumb-title">Terms Of Service</h2>
                <ul class="breadcrumb-menu">
                    <li><a href="index.html">Home</a></li>
                    <li class="active">Terms Of Service</li>
                </ul>
            </div>
        </div>


        <div class="py-120">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="terms-content">
                                <h3>Terms & Conditions - Your Guide to Hassle-Free Travel with Cars53!</h3>
    <p>Before you book your next ride with Cars53, it's important to familiarize yourself with our terms
        and conditions. Founded in 2024, Cars53 is committed to providing commuters in Madhya
        Pradesh and Rajasthan with affordable, reliable, and convenient travel solutions.
        By using our services, you agree to abide by these terms and conditions, which cover
        everything from booking procedures to payment terms to cancellation policies.
        Our goal is to make commuting stress-free for our customers, and our terms and conditions are
        designed to help us achieve that goal. So before you hit the road with Cars53, take a moment to
        review our terms and conditions and ensure that you're familiar with all the important details.</p>
    
    <h3>Experience the Cars53 Difference:</h3>
    <ul>
        <li><strong>Transparent Policies:</strong> We believe in transparency and honesty. Our terms and conditions
            are designed to clearly outline our policies and procedures, so you know exactly what to expect
            when you travel with Cars53.</li>
        <li><strong>Customer Satisfaction:</strong> Your satisfaction is our top priority. That's why we've created terms
            and conditions that are designed to protect your rights and ensure that you have a positive
            experience every time you travel with us.</li>
        <li><strong>Compliance:</strong> Our terms and conditions are designed to ensure compliance with all
            applicable laws and regulations, so you can travel with confidence knowing that you're in safe
            hands.</li>
        <li><strong>Fairness:</strong> We believe in treating our customers with fairness and respect. Our terms and
            conditions are designed to protect your rights and ensure that you're treated fairly in all your
            interactions with Cars53.</li>
    </ul>

    <p>Join us on our journey to make commuting a breeze with Cars53!</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </main>
    <Footer/>
    </>
    )
}
export default Terms